import { getServerSideEnvProps } from "@contexts/environment";
import { mergeGetServerSideProps } from "@utils/next";
import Error from "next/error";

interface Props {
  status: number;
}

export default function Page({ status }: Props) {
  return <Error statusCode={status} />;
}

export const getServerSideProps = mergeGetServerSideProps(
  // Must be included on all pages, otherwise Next.js build fails
  getServerSideEnvProps,
  async (context) => ({
    props: { status: context.res.statusCode } satisfies Props,
  }),
);
